exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-holding-js": () => import("./../../../src/pages/holding.js" /* webpackChunkName: "component---src-pages-holding-js" */),
  "component---src-templates-artist-template-js": () => import("./../../../src/templates/artist-template.js" /* webpackChunkName: "component---src-templates-artist-template-js" */),
  "component---src-templates-listen-template-js": () => import("./../../../src/templates/listen-template.js" /* webpackChunkName: "component---src-templates-listen-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-playlist-template-js": () => import("./../../../src/templates/playlist-template.js" /* webpackChunkName: "component---src-templates-playlist-template-js" */),
  "component---src-templates-track-template-js": () => import("./../../../src/templates/track-template.js" /* webpackChunkName: "component---src-templates-track-template-js" */)
}

