import { graphql, useStaticQuery } from "gatsby";
import { JP } from "../constants/locales";

// eslint-disable-next-line
export default function (locale) {
  const data = useStaticQuery(graphql`
    {
      copyEn: allContentfulMicrocopy(filter: {node_locale: {eq: "en-US"}}) {
        nodes {
          ...ContentfulMicrocopyFragment
        }
      }
			copyJp: allContentfulMicrocopy(filter: {node_locale: {eq: "ja-JP"}}) {
        nodes {
          ...ContentfulMicrocopyFragment
        }
      }
    }
  `)
  return locale && locale === JP ? data.copyJp.nodes[0] : data.copyEn.nodes[0]
}

export const ContentfulMicrocopyFragment = graphql`
	fragment ContentfulMicrocopyFragment on ContentfulMicrocopy {
		aboutTheArtist
		allArtists
		allRightsReserved
		checkMyLocation
		clickdragswipe
		home
		locationPrompt1
		locationPrompt2
		locationPrompt3
		musicity
		moreArtists
		nevermind
		preview
		related
		sharePlaylist
		shareTrack
		soundspacememoryexperience
		view
		viewArtist
		viewProject
		youAreListeningTo
	}
`