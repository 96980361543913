// Dependencies
import React, { useContext, useCallback, useRef, useEffect } from "react";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import TrackLocationPrompt from "../components/TrackLocationPrompt";
// import { Loading } from "../components/Icons";
// Context
import { MusicContext, MusicActionsContext } from "../context/MusicContext";
import { GeolocationContext } from "../context/GeolocationContext";
// Constants
import Theme from "../constants/map-theme";

const TrackListMap = ({ slug, tracks, locale }) => {

  // Geolocation
	// eslint-disable-next-line
  const [geofencedTrack, setGeofencedTrack, userLocation, getUserLocation, isUserNearLocation] = useContext(GeolocationContext);

  // Music context
	// eslint-disable-next-line
  const [play, pause, stop, seek, load] = useContext(MusicActionsContext);
  const [activeTrack] = useContext(MusicContext);

  // Refs
  const googleMap = useRef();
  const bounds = useRef();
  const markers = useRef([]);
  const userMarker = useRef();
  const geofencedArea = useRef();

  // DOM set ref callback
  const initMap = useCallback((node) => {
    if (node) {
      googleMap.current = new window.google.maps.Map(node, {
        zoom: 13,
        maxZoom: 17,
				minZoom: 3,
        center: { lat: 51.53641910591854, lng: -0.04563527987136329 },
        styles: Theme.dark,
        disableDefaultUI: true
      })
      bounds.current = new window.google.maps.LatLngBounds();

      updateMarkers();
    }
		// eslint-disable-next-line
  }, [])

  const addMarker = (track) => {
    const { title, location, contentful_id } = track;
    const isPlaying = activeTrack && (activeTrack.contentful_id === contentful_id);

    const marker = new window.google.maps.Marker({
      title: title,
      map: googleMap.current,
      position: { lat: location.lat, lng: location.lon },
      animation: isPlaying ? window.google.maps.Animation.Po : null,
      icon: isPlaying ? '/mscty-map-icon-active.svg' : '/mscty-map-icon.svg'
    })

    // Save ref
    const ref = { track, marker };
    markers.current.push(ref);

    // Set bounds
    const loc = new window.google.maps.LatLng({ lat: location.lat, lng: location.lon })
    bounds.current.extend(loc);

    marker.addListener('click', (event) => {
      if (track.locationRadius) {
        setGeofencedTrack(track)
      } else {
        if (slug) {
          load(track, { slug, tracks})
        } else {
          load(track)
        }
      }
    })
  }

  const updateMarkers = () => {
    if (markers.current.length > 0) {
      markers.current.forEach(({ marker }, i) => {
        marker.setMap(null)
      })
    }

    tracks.forEach((track, index) => {
      if (track.location) {
        addMarker(track)
      }
    });

    googleMap.current.fitBounds(bounds.current)
    googleMap.current.panToBounds(bounds.current)
  }

  // Update user location when it changes...
  useEffect(() => {
    if (userLocation) {
      const position = {
        lat: userLocation.coords.latitude,
        lng: userLocation.coords.longitude
      };

      if (userMarker.current) {
        userMarker.current.setPosition(position)
      } else {
        userMarker.current = new window.google.maps.Marker({
          title: 'You are here!',
          map: googleMap.current,
          position: position,
          animation: window.google.maps.Animation.Po,
          icon: '/user-location.svg'
        })
      }

      googleMap.current.panTo(position)
    }
  }, [userLocation])

  // On track change...
  useEffect(() => {
    if (activeTrack && googleMap.current) {
      updateMarkers();

      if (geofencedArea.current) {
        geofencedArea.current.setMap(null)
      }
    }
		// eslint-disable-next-line
  }, [activeTrack])

  // If geofencedTrack...
  useEffect(() => {
    const map = googleMap.current;
    if (geofencedTrack) {
      if (geofencedArea.current) {
        geofencedArea.current.setMap(null);
      }
      geofencedArea.current = new window.google.maps.Circle({
        strokeColor: "#FFFFFF",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FFFFFF",
        fillOpacity: 0.35,
        map,
        center: {
          lat: geofencedTrack.location.lat,
          lng: geofencedTrack.location.lon
        },
        radius: geofencedTrack.locationRadius,
      })
      bounds.current.union(geofencedArea.current.getBounds());
      googleMap.current.fitBounds(bounds.current)
      googleMap.current.panToBounds(bounds.current)
    }
  }, [geofencedTrack])

  return (
    <div className="map__wrapper">
      <div className="map" ref={initMap}></div>
      <TrackLocationPrompt locale={locale} />
    </div>
  )
}

export default TrackListMap
