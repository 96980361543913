// Dependencies
import React, { useContext, useRef } from "react";
import { useTransition, animated } from 'react-spring';
import { Link } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
import useMicrocopy from "../hooks/useMicrocopy";
// Context
import { MusicContext, MusicActionsContext } from "../context/MusicContext";
import { GeolocationContext } from "../context/GeolocationContext";
// Components
import Play, { Pause } from "../components/Icons";
import { usePlayVideo } from "../components/VideoPlayer";
import TrackListMap from "../components/TrackListMap";

export const PlayButton = ({ track, playlist }) => {

  // Content
  const { contentful_id, vimeoUrl } = track;

  // Context
	// eslint-disable-next-line
  const [play, pause, stop, seek, load] = useContext(MusicActionsContext);
	// eslint-disable-next-line
  const [activeTrack, showPlayer, playerState] = useContext(MusicContext);
	// eslint-disable-next-line
  const [geofencedTrack, setGeofencedTrack] = useContext(GeolocationContext);
  const isActiveTrack = activeTrack && (activeTrack.contentful_id === contentful_id); //  && !playerState.paused

  // UI
  const handleClick = () => {
    if (isActiveTrack) {
      // If track already loaded, pause/play toggle...
      if (playerState.paused) {
        play()
      } else {
        pause()
      }
    } else {
      // Otherwise load in new track...
      if (track.locationRadius) {
        setGeofencedTrack(track)
      } else {
        load(track, playlist)
      }
    }
  }

  const [handleVideoClick] = usePlayVideo(vimeoUrl);

  return (
    <button onClick={vimeoUrl ? handleVideoClick : handleClick}>
      {(isActiveTrack && !playerState.paused) ? (
        <Pause />
      ) : (
        <Play />
      )}
    </button>
  )
}

export const PlayTrackButton = (props) => {

  // Content
  const { title, artists, playlist } = props;

  const subtitle = artists ? artists[0].name : props.subtitle;

  return (
    <div className="play-track-button p2">
      <PlayButton track={props} playlist={playlist} />
      <div className="info">
        <div className="title"><span>{title}</span></div>
        <strong>{subtitle}</strong>
      </div>
    </div>
  )
}

const Playbar = ({ time, duration, seek }) => {

  const element = useRef();

  const handleClick = ({ clientX, target }) => {
    const { left, width } = target.getBoundingClientRect();
    seek(duration * ((clientX - left) / width))
  }

  return (
    <div className="playbar" ref={element} onClick={handleClick}>
      <div className="scrubber" style={{
        transform: (time && duration) ? `scale(${time / duration}, 1)` : `scale(0, 1)`,
        transformOrigin: `left top`
      }} />
    </div>
  )
}

const Player = ({ title, subtitle, artists, mp3, link }) => {

	// eslint-disable-next-line
  const [activeTrack, showPlayer, playerState] = useContext(MusicContext);
	// eslint-disable-next-line
  const [play, pause, stop, seek, load] = useContext(MusicActionsContext);

  subtitle = artists ? artists[0].name : subtitle;

  return (
    <div className="play-track-button">
      {playerState.paused ? (
        <button onClick={() => play()}>
          <Play />
        </button>
      ) : (
        <button onClick={pause}>
          <Pause />
        </button>
      )}
      {link ? (
        <Link to={link} className="info">
          <div className="title"><span>{title}</span></div>
          <strong>{subtitle}</strong>
        </Link>
      ) : (
        <div className="info">
          <div className="title"><span>{title}</span></div>
          <strong>{subtitle}</strong>
        </div>
      )}
      <Playbar {...playerState} seek={seek} />
    </div>
  )
}

const MusicPlayer = ({ location, locale }) => {

	const { view, home, preview } = useMicrocopy(locale)

	// eslint-disable-next-line
  const [activeTrack, showPlayer, playerState, currentPlaylist] = useContext(MusicContext);
	// eslint-disable-next-line
  const [geofencedTrack, setGeofencedTrack] = useContext(GeolocationContext);
  // const currentTrack = geofencedTrack || activeTrack;
  const isTrackOrPlaylist = RegExp('^/(listen|track)/..*$').test(location.pathname);
  const link = currentPlaylist && currentPlaylist.slug ? `/listen/${currentPlaylist.slug}` : activeTrack ? `/track/${activeTrack.slug}` : '';

  // UX
  const transitions = useTransition(showPlayer, null, {
    from: { transform: `translateY(110%)` },
    enter: { transform: `translateY(0%)` },
    leave: { transform: `translateY(110%)` },
  });

  return transitions.map(({ item, key, props }) =>
    item && (
      <animated.div className="music-player" style={props} key={key}>
        <div className="music-player-wrapper">
          {geofencedTrack && !isTrackOrPlaylist && (
            <div className="music-player-map">
              <TrackListMap tracks={[geofencedTrack]} locale={locale} />
            </div>
          )}
          <div className="music-player-inner">
            {activeTrack ? (
              <Player {...activeTrack} link={link} />
            ) : geofencedTrack ? (
              <Player {...geofencedTrack} title={`${geofencedTrack.title} (${preview})`} link={link} />
            ) : null}
            {activeTrack && (
              <div className="music-player-actions">
                <Link to={link} className="btn">{view}</Link>
                <Link to={'/listen'} className="btn">{home}</Link>
              </div>
            )}
          </div>
        </div>
      </animated.div>
    )
  )
}

export default MusicPlayer
