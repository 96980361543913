// Dependencies
import React, { useState } from "react";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
// import Image from "../components/Image";

// var rad = function(x) {
//   return x * Math.PI / 180;
// };
//
// var getDistance = function(p1, p2) {
//   var R = 6378137; // Earth’s mean radius in meter
//   var dLat = rad(p2.lat() - p1.lat());
//   var dLong = rad(p2.lng() - p1.lng());
//   var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//     Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) *
//     Math.sin(dLong / 2) * Math.sin(dLong / 2);
//   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//   var d = R * c;
//   return d; // returns the distance in meter
// };

export const GeolocationContext = React.createContext();

const GeolocationProvider = ({ children }) => {

  // State
  const [userLocation, setLocation] = useState(null);
  const [geofencedTrack, setGeofencedTrack] = useState(null);

  const isUserNearLocation = (position, trackPosition, radius, callback) => {
    const user = new window.google.maps.LatLng({ lat: position.coords.latitude, lng: position.coords.longitude });
    const target = new window.google.maps.LatLng({ lat: trackPosition.lat, lng: trackPosition.lon });
    const distance = window.google.maps.geometry.spherical.computeDistanceBetween(user, target);
    callback(distance <= radius);
  }

  const getUserLocation = (callback) => {
    window.navigator.geolocation.getCurrentPosition(position => {
      setLocation(position);
      callback(position);
    }, error => {
      console.error(error)
    })
  }

  return (
    <GeolocationContext.Provider value={[geofencedTrack, setGeofencedTrack, userLocation, getUserLocation, isUserNearLocation]}>
      {children}
    </GeolocationContext.Provider>
  )
}

export default GeolocationProvider
