// Dependencies
import React, { useContext } from "react";
import { useTransition, animated } from 'react-spring';
import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
import useVimeo from "../hooks/useVimeo";
// Context
import { VideoContext, VideoActionsContext } from "../context/VideoContext";
import { MusicActionsContext } from "../context/MusicContext";
// Components
import Play, { Close } from "../components/Icons";
// import RichText from "../components/RichText";

export function usePlayVideo(video) {

  const [setActiveVideo] = useContext(VideoActionsContext);
	// eslint-disable-next-line
  const [playMusic, pauseMusic, stopMusic] = useContext(MusicActionsContext);

  const handleClick = () => {
    if (video) {
      setActiveVideo(video);
      stopMusic();
    }
  }

  return [handleClick]
}

export const VideoPlayerButton = ({ video, titles }) => {

  const [handleClick] = usePlayVideo(video);

  return (
    <div className="video-cta">
      <button onClick={handleClick}>
        <Play />
      </button>
      {/* <div>
        {titles ? <RichText content={titles} /> : 'Watch video'}
      </div> */}
    </div>
  )
}

const CloseVideoPlayerButton = () => {

  const [setActiveVideo] = useContext(VideoActionsContext);

  // Show/Hide
  // const timer = useRef();
  // const [hide, set] = useState(false);
  // const style = { opacity: !hide ? 1 : 0 };
  //
  // const fadeOut = () => {
  //   clearTimeout(timer.current);
  //   set(true)
  // }
  //
  // const setTimer = () => {
  //   console.log('mousemove')
  //   clearTimeout(timer.current)
  //   timer.current = setTimeout(fadeOut, 1500);
  //   set(false);
  // }
  //
  // useEffect(() => {
  //   window.addEventListener('mousemove', setTimer, false)
  //   return () => window.removeEventListener('mousemove', setTimer, false)
  // }, [])

  // UI
  const handleClick = () => {
    setActiveVideo(null)
  }

  return (
    <button className="close-video-player-btn" onClick={handleClick}>
      <Close />
    </button>
  )
}

const Vimeo = ({ url }) => {

  const [ref] = useVimeo({
    url: url || 'https://vimeo.com/321249877',
    byline: false,
    color: "#FFF",
    portrait: false,
    title: false,
    autoplay: true
  });

  return (
    <div className="video-container" ref={ref} />
  )
}

const YouTube = ({ id }) => {
	return (
		<div className="video-container youtube">
			{id && (
				<iframe
					width="100%"
					height="100%"
					src={`https://www.youtube.com/embed/${id}?autoplay=1&modestbranding=1`}
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					title={`YouTube ${id}`}
				/>
			)}
		</div>
	)
}

const VideoPlayer = () => {

  const [activeVideo] = useContext(VideoContext);

  // UX
  const transitions = useTransition(activeVideo, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions.map(({ item, key, props }) =>
    item && (
      <animated.div className="video-player" style={props} key={key}>
        <CloseVideoPlayerButton />
				{activeVideo && (
					activeVideo.vimeoUrl ? (
						<Vimeo url={activeVideo.vimeoUrl} />
					) : activeVideo.youTubeId && (
						<YouTube id={activeVideo.youTubeId} />
					)
				)}
      </animated.div>
    )
  )
}

export default VideoPlayer

export const ContentfulVideoFragment = graphql`
  fragment ContentfulVideoFragment on ContentfulVideo {
		__typename
    id
    vimeoUrl
		youTubeId
    coverArtwork {
      ...ContentfulArtworkFragment
    }
    titles {
      raw
    }
  }
`
