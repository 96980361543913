// Dependencies
import React, { useState, useEffect, useContext } from "react";
import { useAudio } from "react-use";
// Hooks
// ...
// Helpers
// ...
// Components
// ...
// Context
import { GeolocationContext } from "../context/GeolocationContext";

export const MusicContext = React.createContext();
export const MusicActionsContext = React.createContext();

const MusicProvider = ({ children }) => {

  // Content
  const [activeTrack, setActiveTrack] = useState(null);
  const [geofencedTrack, setGeofencedTrack] = useContext(GeolocationContext);
  const [currentPlaylist, setPlaylist] = useState(null);
  // const src = activeTrack && activeTrack.mp3 ? activeTrack.mp3.file.url : '';

  // const src = activeTrack ? activeTrack.

  const src = geofencedTrack ? (
    geofencedTrack.mp3Preview ? geofencedTrack.mp3Preview.file.url : ''
  ) : activeTrack ? (
    activeTrack.mp3 ? activeTrack.mp3.file.url : ''
  ) : '';

  // UX
  const [showPlayer, setShowPlayer] = useState(false);

  // useAudio
  const [audio, state, controls] = useAudio({
    src: src,
    autoPlay: true,
  });

  // UI
  const load = (track, playlist) => {
    if (track) {
      setActiveTrack(track)
      controls.play()
      // setShowPlayer(true)

      // Handle playlist...
      if (playlist) {
        setPlaylist(playlist)
      } else if (playlist === false) {
        // Eg. in <RichText /> the track component is manually passing through "false"
        // as opposed to places where the track being played may be part of the same list
        setPlaylist(null)
      }
    }
  }

  const play = () => {
    if (activeTrack) {
      controls.play();
      // setShowPlayer(true)
    }
  }

  const pause = () => {
    controls.pause();
  }

  const stop = () => {
    controls.pause()
    // setShowPlayer(false)
  }

  const seek = (time) => {
    controls.seek(time)
  }

  const playNextTrack = () => {
    if (currentPlaylist?.tracks.length > 1) {
      // Check where activeTrack is in the playlist...
      const currentTrackIndex = currentPlaylist.tracks.findIndex((item) => item.contentful_id === activeTrack.contentful_id);

      // If there is a next track, play it...
      if (currentTrackIndex && (currentTrackIndex < (currentPlaylist.tracks.length - 1))) {
        const nextTrack = currentPlaylist.tracks[currentTrackIndex + 1];
        if (nextTrack.locationRadius) {
          setGeofencedTrack(nextTrack)
        } else {
          load(nextTrack)
        }
      }
    } else {
			setActiveTrack(null)
		}
  }

  // When track ends...
  useEffect(() => {
    if (state && (state.time / state.duration) >= 1) {
      playNextTrack();
    }
		// eslint-disable-next-line
  }, [state.time])

  // Show the player if there is a geofencedTrack...
  useEffect(() => {
    if (geofencedTrack || activeTrack) {
      setShowPlayer(true)
    } else {
      setShowPlayer(false)
    }
  }, [geofencedTrack, activeTrack])

  return (
    <MusicContext.Provider value={[activeTrack, showPlayer, state, currentPlaylist]}>
      <MusicActionsContext.Provider value={[play, pause, stop, seek, load]}>
        {children}
        <div>{audio}</div>
      </MusicActionsContext.Provider>
    </MusicContext.Provider>
  )
}

export default MusicProvider
