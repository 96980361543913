// Dependencies
import React, { useContext, useEffect, useState } from "react";
// Hooks
import useMicrocopy from "../hooks/useMicrocopy";
// Components
import { Loading } from "../components/Icons";
// Context
import { MusicActionsContext } from "../context/MusicContext";
import { GeolocationContext } from "../context/GeolocationContext";

const TrackLocationPrompt = ({ locale }) => {

  // Geolocation context
	// eslint-disable-next-line
  const [geofencedTrack, setGeofencedTrack, userLocation, getUserLocation, isUserNearLocation] = useContext(GeolocationContext);

  // Music context
	// eslint-disable-next-line
  const [play, pause, stop, seek, load] = useContext(MusicActionsContext);

	// Microcopy
	const { 
		locationPrompt1, 
		locationPrompt2, 
		locationPrompt3, 
		checkMyLocation, 
		nevermind, 
		youAreListeningTo 
	} = useMicrocopy(locale)

  // UX
  // 0 = ready, 1 = loading, 2 = success, 3 = failure, 4 = error
  const [status, setStatus] = useState(0);

  useEffect(() => {
    let timer;

    // While making the attempt, timout attempt if no action...
    if (status === 1) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setStatus(4)
      }, 12000);
    }

    // Remove prompt after success, fail or error
    if (status > 1) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setStatus(0)
        // setGeofencedTrack(null)
      }, 5000);
    }

    return () => {
      clearTimeout(timer);
    }
  }, [status])

  // UI
  const handleClick = () => {
    setStatus(1);
    getUserLocation(position => {
      isUserNearLocation(position, geofencedTrack.location, geofencedTrack.locationRadius, canPlay => {
        if (canPlay) {
          load(geofencedTrack)
          setGeofencedTrack(null)
          setStatus(2)
        } else {
          setStatus(3)
        }
      })
    })
  }

  const cancel = () => {
    setStatus(0)
    setGeofencedTrack(null)
  }

  return (
    geofencedTrack && (
      <div className={`map__prompt ${status === 1 ? 'is-loading' : ''}`}>
        {status === 0 ? (
          <div>
            <p>{locationPrompt1}</p>
            <button className="btn mr" onClick={handleClick}>{checkMyLocation}</button>
            <button className="btn" onClick={cancel}>{nevermind}</button>
          </div>
        ) : status === 1 ? (
          <div>
            {/* <p>To properly experience this track, you must be near to this location in order to listen.</p> */}
            <div className="spinner">
              <Loading />
            </div>
          </div>
        ) : status === 2 ? (
          <div>
            <p>{youAreListeningTo} {geofencedTrack.title}</p>
          </div>
        ) : status === 3 ? (
          <div>
            <p>{locationPrompt2}</p>
          </div>
        ) : status === 4 ? (
          <div>
            <p>{locationPrompt3}</p>
          </div>
        ) : null}
      </div>
    )
  )
}

export default TrackLocationPrompt
