// Dependencies
import React, { useState } from "react";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
// import Image from "../components/Image";

export const VideoContext = React.createContext();
export const VideoActionsContext = React.createContext();

const VideoProvider = ({ children }) => {

  const [activeVideo, setActiveVideo] = useState(null);

  return (
    <VideoContext.Provider value={[activeVideo]}>
      <VideoActionsContext.Provider value={[setActiveVideo]}>
        {children}
      </VideoActionsContext.Provider>
    </VideoContext.Provider>
  )
}

export default VideoProvider
