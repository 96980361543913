// Dependencies
import React from "react";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Context
import GeolocationProvider from "../context/GeolocationContext"
import MusicProvider from "../context/MusicContext"
import VideoProvider from "../context/VideoContext"
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import MusicPlayer from "../components/MusicPlayer"

const App = ({ location, children, pageContext: { locale } }) => {

  return (
    <GeolocationProvider>
      <MusicProvider>
        <VideoProvider>
          {children}
          <MusicPlayer location={location} locale={locale} />
        </VideoProvider>
      </MusicProvider>
    </GeolocationProvider>
  )
}

export default App
